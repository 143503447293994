import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Record as RRecord,
  Runtype,
  Static,
  String,
  Union,
} from "runtypes";

import { OrgRoleLiteral } from "./enums";
import { KernelSize } from "./kernelConfigTypes";
import { getNormalEnum } from "./runtypeEnums";
import { typedObjectKeys } from "./utils/typedObjects";

/*eslint sort-keys: ["error", "asc", {caseSensitive: false, natural: true, allowLineSeparatedGroups: true}] -- expect object keys to be sorted*/
/**
 * FeatureSet expresses a set of features that are disabled/enabled
 * for a given product pricing plan, ie. Community, Pro, Enterprise plans.
 *
 * This should not be used to define disabled/enabled features for specific orgs
 * outside the scope of which pricing plan the org is on.
 */
const FeatureGateSetBase = {
  name: String,

  /** Project view graph analytics */
  advancedProjectViewStats: Boolean,
  /** Admins can disable CSV downloads for App-only users at the org level */
  allowDisableCsvDownload: Boolean,
  allowDisableWorkspaceSharing: Boolean,
  /**
   * Maximum number of monthly magic requests per user per month.
   *
   * Set to null to indicate no limit, zero to disable magic entirely
   */
  allowedMonthlyMagicRequests: Number.Or(Null),
  /** Admins can disable file uploads across all projects at the org level */
  allowRestrictFileUploads: Boolean,
  /** App kernel idle timeout in milliseconds */
  appIdleTimeout: Number,
  auditLogs: Boolean,
  /** Admins can configure automatic arhcive criteria */
  autoArchive: Boolean,
  chatSupport: Boolean,
  collections: Boolean,
  components: Boolean,
  configurableKernelSize: Boolean,
  customAppIcon: Boolean,
  /** custom code formatting (e.g. sqlfluff) is enabled */
  customCodeFormatting: Boolean,
  /** Admins can configure custom color palettes */
  customColorPalettes: Boolean,
  customCronScheduledRuns: Boolean,
  /** Admins can configure query permissions for shared data connections */
  databaseQueryPermissions: Boolean,
  /** Whether or not an org can configure and use data retention policies */
  dataRetention: Boolean,
  dbtIntegration: Boolean,
  dbtMetricCell: Boolean,
  /** Default kernel size for new projects */
  defaultKernelSize: KernelSize,
  directorySync: Boolean,
  /** number of days of edit history viewable */
  editHistoryDays: Number,
  /** An org can designate statuses as endorsed */
  endorsements: Boolean,
  /** Explore from a published app is enabled */
  exploreFromHere: Boolean,
  /** Ability to assign the explorer role to workspace members */
  explorerRole: Boolean,
  /** Access to the public Hex API */
  externalApi: Boolean,
  externalFileIntegrations: Boolean,
  /** Whether or not the GitHub integration (i.e. package import) is active */
  githubIntegration: Boolean,
  /** Whether or not GitHub sync repository and GitLab sync project support is active */
  githubSync: Boolean, // used for both github and gitlab sync
  /** Whether or not the org can create groups */
  groups: Boolean,
  hexMagic: Boolean,
  /** Logic kernel idle timeout in milliseconds */
  logicIdleTimeout: Number,
  /**
   * Any compute profile with a memory limit larger than this `maxKernelSizes` compute profile will be shown with a tooltip
   * which compute profiles are shown at all is determined by the `kernelSizesForClient` variable
   */
  maxKernelSizes: KernelSize,
  /** Kernel max execution timeout in milliseconds  */
  maxRuntime: Number,
  /** Whether or not an org can use publish reviews at all */
  optionalPublishReviews: Boolean,
  /** Whether or not org-level connections can be created and used */
  orgDataConnections: Boolean,
  /** Whether or not org-level files can be created and used */
  orgFiles: Boolean,
  /**
   * Whether or not a project (ie. Hex) can be shared to the org
   *
   * @deprecated in favor of enforcing a strict project limit @see projectLimit
   */
  orgProjectSharing: Boolean,
  /** Whether or not org-level secrets can be created and used */
  orgSecrets: Boolean,
  /** Whether we automatically prerun cached apps upon view */
  prerunApps: Boolean,
  /** Whether presentation mode is available for published apps */
  presentationMode: Boolean,
  /** The total number of projects that can be created across an org (excluding demo projects), null means infinite */
  projectLimit: Number.Or(Null),
  /** Whether or not an org can require publish reviews */
  requiredPublishReviews: Boolean,
  scheduledRunLimit: Number,
  /** Whether or not scheduled runs will execute */
  scheduledRuns: Boolean,
  /** Whether scheduled runs can be managed from the admin UI */
  scheduledRunsManager: Boolean,
  /** Whether or not schedules can be configured to include notifications */
  scheduleNotifications: Boolean,
  /** Includes both scheduled run screenshots and on-demand PDF downloads */
  screenshots: Boolean,
  /** Whether or not server git providers (e.g. GHE Server) are active */
  serverGitProviders: Boolean,
  /**
   * Max number of collections sharable with workspace or users + groups, per org, null means infinite. Set to 0 to disable sharing.
   * Value will be disregarded if `collections` feature set field is false
   */
  sharedCollectionLimit: Number.Or(Null),
  /**
   * Max number of components sharable with workspace or users + groups, per org, null means infinite. Set to 0 to disable sharing.
   * Value will be disregarded if `components` feature set field is false
   */
  sharedComponentLimit: Number.Or(Null),
  /** Whether or not scheduled run notifications can be sent to Slack channels */
  slackChannelNotifications: Boolean,
  snowflakeOAuthConnections: Boolean,
  /** Whether or not SSO (not OAuth) is enabled or configurable */
  sso: Boolean,
  /**
   * Whether or not more than 5 categories/statuses can be created
   *
   * @deprecated - no longer checked in FE
   */
  unlimitedProjectLabels: Boolean,
  usersLastActive: Boolean,
  /** number of days of version history viewable, null means infinite */
  versionHistoryDays: Number.Or(Null),
  /** Whether view data for a chart is enabled */
  viewData: Boolean,
  /** Can admins see analytics for workspace assets */
  workspaceUsageAnalytics: Boolean,
};
/*eslint-disable -- expect object keys to be sorted*/

export const FeatureGateSet = RRecord(FeatureGateSetBase);
export type FeatureGateSet = Static<typeof FeatureGateSet>;

export type FeatureGate = keyof FeatureGateSet;

export const FeatureGateName = Union(
  ...(typedObjectKeys(FeatureGateSetBase).map((k) => Literal(k)) as any),
) as Runtype<keyof typeof FeatureGateSetBase>;

export const FeatureGateTierLiteral = Union(
  Literal("COMMUNITY"),
  Literal("PROFESSIONAL"),
  Literal("TEAM"),
  Literal("ENTERPRISE"),
);
export type FeatureGateTier = Static<typeof FeatureGateTierLiteral>;
export const FeatureGateTier = getNormalEnum(FeatureGateTierLiteral);

export const LABELS_BY_FEATURE_GATE_TIER: Record<FeatureGateTier, string> = {
  [FeatureGateTier.COMMUNITY]: "Community",
  [FeatureGateTier.ENTERPRISE]: "Enterprise",
  [FeatureGateTier.TEAM]: "Team",
  [FeatureGateTier.PROFESSIONAL]: "Professional",
};

export const SEAT_PRICE_BY_FEATURE_GATE_TIER: Record<
  FeatureGateTier,
  number | null
> = {
  [FeatureGateTier.COMMUNITY]: 0,
  [FeatureGateTier.ENTERPRISE]: null,
  [FeatureGateTier.TEAM]: 75,
  [FeatureGateTier.PROFESSIONAL]: 36,
};

export const TIER_ORDERING = [
  FeatureGateTier.COMMUNITY,
  FeatureGateTier.PROFESSIONAL,
  FeatureGateTier.TEAM,
  FeatureGateTier.ENTERPRISE,
];

/**
 * Compares tier in terms of "pricing" order specified in TIER_ORDERING.
 *
 * Returns a postive value if compared tier is an "upgrade".
 *
 * Returns a negative value if compared tier is a "downgrade".
 *
 * Returns 0 if tiers are equal.
 */
export const compareTiers = ({
  comparingTier,
  currentTier,
}: {
  currentTier: FeatureGateTier;
  comparingTier: FeatureGateTier;
}): number => {
  return (
    TIER_ORDERING.indexOf(comparingTier) - TIER_ORDERING.indexOf(currentTier)
  );
};

export const isTierSuperSet = (
  tier: FeatureGateTier,
  targetTier: FeatureGateTier,
): boolean => {
  if (TIER_ORDERING.indexOf(tier) === -1) {
    return false;
  }

  return TIER_ORDERING.indexOf(tier) >= TIER_ORDERING.indexOf(targetTier);
};

export const FeatureGateClickedSourceLiteral = Union(
  Literal("BANNER"),
  Literal("FEATURE_DISABLED"),
);

export const FeatureGateClickedSource = getNormalEnum(
  FeatureGateClickedSourceLiteral,
);
export type FeatureGateClickedSource = Static<
  typeof FeatureGateClickedSourceLiteral
>;

export const FeatureGateClickedEventData = RRecord({
  triggeredFrom: FeatureGateClickedSourceLiteral,
  featureGateType: Array(FeatureGateName),
  orgRole: OrgRoleLiteral.optional(),
});

export type FeatureGateClickedEventData = Static<
  typeof FeatureGateClickedEventData
>;
