import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  KernelMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext";

type UseKernelSelectorResult<T, S> = S extends false ? T : T | undefined;

type EqualityFnType<T, S> = (
  left: UseKernelSelectorResult<T, S>,
  right: UseKernelSelectorResult<T, S>,
) => boolean;

export interface UseKernelSelectorArgs<T, S extends boolean> {
  selector: (kernel: KernelMP | null) => T;
  equalityFn?: EqualityFnType<T, S>;
  /**
   * @default false
   */
  safe?: S;
}

export function useKernelSelector<T, S extends boolean = false>({
  equalityFn,
  safe,
  selector,
}: UseKernelSelectorArgs<T, S>): UseKernelSelectorResult<T, S> {
  const { appSessionId } = useSessionContext({ safe }) ?? {
    appSessionId: undefined,
  };

  return useSelector((state) => {
    // if appSessionId is null and we haven't errored above, then `safe` must be true.
    if (appSessionId == null) {
      return undefined as UseKernelSelectorResult<T, S>;
    }
    const kernel = appSessionMPSelectors
      .getKernelSelectors(appSessionId)
      .safeSelect(state);

    return selector(kernel);
  }, equalityFn);
}

export interface UseKernelGetterArgs<A extends unknown[], T> {
  selector?: (kernel: KernelMP | null, ...args: A) => T;
}

export type UseKernelGetterResult<A extends unknown[], T> = (...args: A) => T;

export function useKernelGetter<A extends unknown[], T = KernelMP>({
  selector,
}: UseKernelGetterArgs<A, T> = {}): UseKernelGetterResult<A, T> {
  const { appSessionId } = useSessionContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const kernel = appSessionMPSelectors
        .getKernelSelectors(appSessionId)
        .safeSelect(store.getState());

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(kernel, ...args)
        : (kernel as unknown as T);
    },
    [appSessionId, store, selectorRef],
  );
}
